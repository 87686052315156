import React, { useEffect } from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import loadable from '@loadable/component'
import tracking from "../utils/tracking";

const Home = loadable(() => import('./../pages/index'), { resolveComponent: ({ Home }) => Home })
const Menu = loadable(() => import("./../components/navigation/Menu"))


const Router = ({ basename }) => {

  return (
    <BrowserRouter basename={basename}>
      <ScrollToTop/>
      <Menu/>
      <Switch>
        <Route exact path={["/"]} component={Home}/>
        <Redirect to={"/"}/>
      </Switch>
    </BrowserRouter>
  )
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    tracking.path(pathname)
  }, [pathname]);
  return null;
};

export default Router;
