import React from "react";

const ca = {
  seo: {
    title: "Lea Lamy Riebel - Afinació, Manteniment i Reparació de pianos",
    description: "Cerques un afinador de pianos a Mallorca? Afinació, manteniment i reparació de tot tipus de pianos a Mallorca - Lea Lamy Riebel"
  },
  menu: {
    maintenance: "Manteniment",
    repair: "Reparació",
    prices: "Preus",
    contact: "Contacte"
  },
  sections: {
    maintenance: {
      title: "Manteniment",
      intro1: "El piano és un instrument valuós i delicat que requereix d'un manteniment regular, tant si es fa servir diàriament com si es fa servir esporàdicament. Per tant, és essencial que un tècnic acreditat mantingui en òptimes condicions tant el seu cos acústic com la mecànica que l'incorpora.",
      intro2: "Perquè el piano es mantingui en aquestes condicions i no es deteriori amb el temps, cal dur a terme tres ajustos de manera periòdica: afinació, regulació i entonació.",
      tuning: {
        title: "Afinació de pianos",
        p1: "Depenent de l'ús que se li doni al piano, s'ha d'afinar amb una o altra freqüència. En el cas d'un piano particular hi ha prou amb fer una o dues afinacions a l'any. Si, d'altra banda, pretenem mantenir en bones condicions un piano en auditoris o sales de concert es requerirà una afinació abans de cada concert per a un bon funcionament.",
        p2: <React.Fragment>Afinar el piano requereix de concentració i d'experiència, es per això que s'ha de fer en un
          entorn silenciós evitant qualsevol tipus d'interferència o distracció, sobretot si el tècnic realitza
          una <strong>afinació d'oïda</strong>.</React.Fragment>,
        p3: "Afinar un piano sembla senzill, però aconseguir una afinació estable i parella requereix anys de pràctica."
      },
      pitchRaise: {
        title: "Pujada de to",
        p1: "Quan un piano està molt de temps sense ser afinat o s'ha transportat recentment es requereix d'una pujada de to. Aquest manteniment es duu a terme en dues visites amb una setmana de diferència. A la primera visita es tensen les cordes per sobre del que és habitual, per a què en la segona visita l'afinació es mantingui el més temps possible. En alguns casos es requerirà de tres visites: dues pujades de to i una afinació final."
      },
      pianoRegulation: {
        title: "Regulació de pianos",
        p1: <React.Fragment>A més d'afinar el piano, és important realitzar una neteja periòdica, tant de l'exterior com
          de l'interior i un seguit de regulacions i reglatge de la seva mecànica. Aquest servei garanteix un <strong>funcionament
            perfecte</strong> i prevé possibles errors o problemes.</React.Fragment>,
        p2: <React.Fragment>Tots aquests components han de quedar perfectament alineats i coincidir amb plena exactitud
          perquè el to del piano no es vegi afectat i el tacte sigui òptim. D'aquí que l'ajust del mecanisme hagi de ser
          molt precís. A causa d'això, aquest procés no pot ser dut a terme per qualsevol usuari. És un treball complex
          que requereix <strong>formació i experiència</strong>
          .</React.Fragment>
      },
      pianoVoicing: {
        title: "Entonació de pianos",
        p1: "Cada piano té el seu propi timbre o ”veu”. En molts de sentits, la veu d'un piano indica la forma en què va ser construït i defineix la seva personalitat. Però amb el temps, com més es toqui un piano, més se sentirà que el martell que colpeja les cordes es gasta i es comprimeix. Aquest enduriment pot fer que el to es torni massa dur o brillant, i serà difícil produir una gamma alta d'expressions, sense importar que intentis millorar el teu estil de toc.",
        p2: <React.Fragment>Es pot corregir aquest desgast de diferentes maneres per tal d'obtenir el to exacte que
          s'està buscant. No obstant això, tingui en compte que un <strong>piano ha de ser regulat i finalment afinat
            abans de ser tocat</strong>.</React.Fragment>,
        p3: "Un altre aspecte a tenir en compte és que el so del piano pot ser modificat encara que el piano no es trobi molt usat o fins i tot en pianos nous. Hem de entendre que el so característic d'un piano no és una cosa rígida sinó que és una cosa que pot modificar-se dins d'un cert rang."
      }
    },
    repair: {
      title: "Reparació",
      p1: "Si un piano ha perdut part de la seva sonoritat o si alguna de les seves funcions mecàniques ja no respon adequadament, segurament aquest piano necessiti una reparació i posada a punt.",
      p2: <React.Fragment>En certes ocasions un piano, ja sigui pel seu ús o pel pas del temps, pot patir el
        deteriorament o el trencament d'algun dels seus components. En aquests casos, la substitució de l'element danyat
        per un de nou i els ajustos corresponents tornaran a l'instrument el seu estat natural de <strong>eficiència i
          òptim funcionament</strong>.
        És important que aquesta intervenció sigui realitzada per un tècnic de pianos qualificat, ja que és l'única
        garantia que es realitzi correctament.</React.Fragment>,
      p3: <React.Fragment>Degut que el canvi o substitució d'aquests components poden diferir molt d'un piano a un
        altre <strong>es farà una valoració i pressupost sobre aquests treballs en el seu domicili sense cap
          compromís</strong>.</React.Fragment>
    },
    prices: {
      title: "Preus",
      p1: <React.Fragment><strong>Cada piano és un món!</strong><br/>No és d'estranyar que en la majoria de casos, ja
        sigui nou o antic, les reparacions necessàries només es puguin determinar després d'una comprovació prèvia de
        l'estat del piano.</React.Fragment>,
      p2: <React.Fragment>Per tant, <strong>tots els preus a continuació serveixen únicament com a guia</strong> i es
        podrà sol·licitar sempre un <strong>pressupost</strong> sense cap compromís. Els preus a continuació no inclouen
        despeses de desplaçament. Per a més informació no dubteu en</React.Fragment>,
      p2link: "contactar-me",
      tuning: "Afinació",
      tuningDiscount: "10% de descompte per escoles de música, conservatoris i hotels.",
      pitchRaise: "Pujada de to",
      regulation: "Regulació",
      voicing: "Entonació",
      repair: "Reparació",
      repairText: "Preu baix pressupost."
    },
    contact: {
      title: "Contacte",
    }
  },
  common: {
    price: "Preu",
    visitVariation: "Varia en funció de les visites",
    pianoVariation: "Varia en funció del piano",
    pianoTechnician: "Tècnic de piano",
    job: "Afinació, manteniment i reparació de pianos a Mallorca"
  }
}

export default ca;