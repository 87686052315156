import React, { createContext, useMemo } from "react";
import lodash from "lodash";

export const defaultLang = "es"
export const languages = ["es", "ca", "en"];
const TranslateContext = createContext({ translate: (t) => t, lang: defaultLang, setLang: () => null, languages });

const TranslateWrapper = ({ children, lang = defaultLang }) => {
  const realLang = languages.includes(lang) ? lang : defaultLang

  const library = useMemo(() => require("./../../data/locale/" + realLang + ".js").default, [realLang])

  const translate = (text) => {
    return lodash.get(library, text) || text
  }

  return (
    <TranslateContext.Provider
      value={{ translate, lang: realLang, languages }}>
      {children}
    </TranslateContext.Provider>
  )
}

export default TranslateWrapper;
export { TranslateContext }
