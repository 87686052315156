import React from "react";

const en = {
  seo: {
    title: "Lea Lamy Riebel - Tuning, Maintenance, and Piano Repairs",
    description: "Are you looking for a piano tuner in Mallorca? Tuning, maintenance and repair of all types of pianos in Mallorca - Lea Lamy Riebel"
  },
  menu: {
    maintenance: "Maintenance",
    repair: "Repair",
    prices: "Prices",
    contact: "Contact"
  },
  sections: {
    maintenance: {
      title: "Maintenance",
      intro1: "The piano is a delicate and valuable instrument that requires regular maintenance, whether it is used daily or occasionally. More importantly, it is essential that an accredited technician keeps its acoustic body and mechanics in optimal conditions.",
      intro2: "In order for the piano to remain in such conditions and not deteriorate over time, it is necessary to carry out three adjustments periodically: tuning, regulation, and voicing.",
      tuning: {
        title: "Piano tuning",
        p1: "Depending on the use given to the piano, it must be tuned with one frequency or another. In the case of a particular piano, it is enough to perform one or two tunings per year. If, on the other hand, we intend to maintain a piano in auditoriums or concert halls in good condition, tuning will be required before each concert for proper operation.",
        p2: <React.Fragment>Tuning the piano requires concentration and experience, this is why it should be done in a
          quiet environment avoiding any type of interference or distraction, especially because the technician
          performs <strong>the tuning by ear</strong>.</React.Fragment>,
        p3: "Tuning a piano sounds simple but achieving a stable and uniform tuning takes years of practice."
      },
      pitchRaise: {
        title: "Pitch Raise",
        p1: "When a piano remains out of tune for a long time or has been recently transported, a pitch raise is required. This maintenance is carried out in two visits one week apart. On the first visit, the strings are tightened more than usual, so that on the second visit the tuning is maintained for as long as possible. In some cases, three visits will be required: two pitch raises and final tuning."
      },
      pianoRegulation: {
        title: "Piano regulation",
        p1: <React.Fragment>In addition to tuning the piano, it is important to carry out
          periodic cleaning, both outside and inside, and a series of
          regulations and adjustments to its mechanics. This service
          guarantees <strong>perfect operation</strong> and prevents possible failures
          or problems. All these components must be perfectly
          aligned and match exactly so that the piano's tone is not
          affected and the touch is optimal.</React.Fragment>,
        p2: <React.Fragment>Thus, the adjustment of the mechanism must be very
          precise. Due to this, this process cannot be carried out by
          any user. It is a complex job that requires <strong>training and experience</strong>
          .</React.Fragment>
      },
      pianoVoicing: {
        title: "Piano voicing",
        p1: "Each piano has its own timbre or “voice”. In many ways, the voice of a piano indicates the way it was built and defines its personality. But over time, the more a piano is played, the more the hammer striking the strings will feel worn and compressed. This hardening can cause the tone to become too harsh or bright, and it will be difficult to produce a high range of expression, no matter how much you try to improve your playing style.",
        p2: <React.Fragment>You can correct this wear in several ways to get the exact
          tone you're looking for. However, keep in mind
          that <strong>a piano has to be regulated and finally tuned before it can be played</strong>.</React.Fragment>,
        p3: "Another aspect to consider is that the sound of the piano can be modified even if the piano has not been used very much or even if it is new. We must understand that the characteristic sound of a piano is not something rigid but something that can be modified within a certain range."
      }
    },
    repair: {
      title: "Repair",
      p1: "If a piano has lost some of its sonority or if any of its mechanical functions no longer respond properly, surely that piano needs a repair and tune-up.",
      p2: <React.Fragment>On certain occasions, a piano, either due to its use or the
        passage of time, can suffer deterioration or breakage of one of
        its components. In such cases, replacing the damaged item with
        a new one and applying the necessary adjustments will return
        the instrument to its natural state
        of <strong>efficiency and optimal function</strong>.
        It is crucial that a qualified piano technician carries
        out this intervention since it is the only guarantee that it is
        carried out correctly.</React.Fragment>,
      p3: <React.Fragment>Due to the fact that the change or substitution of these
        components can differ a lot from one piano to
        another, <strong>an assessment and budget will be made on these works at your home without any
          commitment</strong>.</React.Fragment>
    },
    prices: {
      title: "Prices",
      p1: <React.Fragment><strong>Every piano is a world!</strong><br/>It's no wonder that in most cases, whether new or
        old, the repairs needed can only be determined after a prior check of the piano's condition.</React.Fragment>,
      p2: <React.Fragment>Therefore, <strong>all the prices below serve only as a guide</strong> and you
        can always request a quote without any commitment. The
        prices below do not include travel expenses. For more
        information do not hesitate to</React.Fragment>,
      p2link: "contact me",
      tuning: "Tuning",
      tuningDiscount: "discount for music schools, conservatories, and hotels.",
      pitchRaise: "Pitch raise",
      regulation: "Regulation",
      voicing: "Voicing",
      repair: "Repair",
      repairText: "depending on the budget."
    },
    contact: {
      title: "Contact",
    }
  },
  common: {
    price: "Price",
    visitVariation: "Varies depending on visits",
    pianoVariation: "Varies depending on the piano",
    pianoTechnician: "Piano technician",
    job: "Tuning, Maintenance, and Piano Repairs"
  }
}

export default en;