import ReactGA from "react-ga";

const goToSection = (sectionName) => {
  ReactGA.event({
    category: "Section",
    action: `Go to ${sectionName}`,
    label: 'button',
    nonInteraction: false
  });
}

const call = () => {
  ReactGA.event({
    category: "Contact",
    action: `Contact by phone`,
    label: 'button',
    nonInteraction: false
  });
}

const email = () => {
  ReactGA.event({
    category: "Contact",
    action: `Contact by email`,
    label: 'button',
    nonInteraction: false
  });
}

const instagram = () => {
  ReactGA.event({
    category: "Social",
    action: `Go to Instagram`,
    label: 'button',
    nonInteraction: false
  });
}

const path = (pathname) => {
  ReactGA.send(pathname);
}

const tracking = { goToSection, path, call, email, instagram }
export default tracking;
