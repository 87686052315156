import React from "react";

const es = {
  seo: {
    title: "Lea Lamy Riebel - Afinación, Mantenimiento y Reparación de pianos",
    description: "Buscas un afinador de pianos en Mallorca? Afinación, mantenimiento y reparación de todo tipo de pianos en Mallorca - Lea Lamy Riebel."
  },
  menu: {
    maintenance: "Mantenimiento",
    repair: "Reparación",
    prices: "Precios",
    contact: "Contacto"
  },
  sections: {
    maintenance: {
      title: "Mantenimiento",
      intro1: "El piano es un instrumento valioso y delicado que requiere de un mantenimiento regular, tanto si se usa a diario como si se usa esporádicamente. Por ende, es esencial que un técnico acreditado mantenga en óptimas condiciones tanto su cuerpo acústico como la mecánica que lo incorpora.",
      intro2: "Para que el piano se mantenga en tales condiciones y no se deteriore con el tiempo, es necesario llevar a cabo tres ajustes de manera periódica: afinación, regulación y entonación.",
      tuning: {
        title: "Afinación de pianos",
        p1: "Dependiendo del uso que se le dé al piano, se debe afinar con una u otra frecuencia. En el caso de un piano particular es suficiente con realizar una o dos afinaciones al año. Si, por otro lado, pretendemos mantener en buenas condiciones un piano en auditorios o salas de concierto se requerirá una afinación antes de cada concierto para un buen funcionamiento.",
        p2: <React.Fragment>Afinar el piano requiere concentración y experiencia, es por eso que se debe hacer en un
          entorno silencioso evitando cualquier tipo de interferencia o distracción, sobretodo si el técnico realiza
          una <strong>afinación de oído</strong>.</React.Fragment>,
        p3: "Afinar un piano parece sencillo, pero lograr una afinación estable y pareja requiere años de práctica."
      },
      pitchRaise: {
        title: "Subida de tono",
        p1: "Cuando un piano permanece mucho tiempo sin ser afinado o se ha transportado recientemente se requiere de una subida de tono. Este mantenimiento se lleva a cabo en dos visitas con una semana de diferencia. En la primera visita se tensan las cuerdas por encima de lo habitual, para que en la segunda visita la afinación se mantenga durante el mayor tiempo posible. En algunos casos se requerirá de tres visitas: dos subidas de tono y una afinación final."
      },
      pianoRegulation: {
        title: "Regulación de pianos",
        p1: <React.Fragment>Además de afinar el piano, es importante realizar una limpieza periódica, tanto del exterior
          como del interior y una serie de regulaciones y reglaje de su mecánica. Este servicio garantiza un <strong>funcionamiento
            perfecto</strong> y previene posibles fallos o problemas.</React.Fragment>,
        p2: <React.Fragment>Todos estos componentes deben quedar perfectamente alineados y coincidir con plena exactitud
          para que el tono del piano no se vea afectado y el tacto sea óptimo. De ahí que el ajuste del mecanismo deba
          ser muy preciso. Debido a esto, este proceso no puede ser llevado a cabo por cualquier usuario. Es un trabajo
          complejo que requiere <strong>formación y experiencia</strong>
          .</React.Fragment>
      },
      pianoVoicing: {
        title: "Entonación de pianos",
        p1: "Cada piano tiene su propio timbre o “voz”. En muchos sentidos, la voz de un piano indica la forma en que fue construido y define su personalidad. Pero con el tiempo, cuanto más se toque un piano, más se sentirá que el martillo que golpea las cuerdas se gasta y se comprime. Este endurecimiento puede hacer que el tono se vuelva demasiado duro o brillante, y va a ser difícil producir una gama alta de expresiones, sin importar que intentes mejorar tu estilo de toque.",
        p2: <React.Fragment>Se puede corregir este desgaste de diferentes maneras a fin de obtener el tono exacto que se
          está buscando. Sin embargo, tenga en cuenta que un <strong>piano tiene que ser regulado y finalmente afinado
            antes de ser tocado</strong>.</React.Fragment>,
        p3: "Otro aspecto a tener en cuenta es que el sonido del piano puede ser modificado aunque el piano no se encuentre muy usado o incluso en pianos nuevos. Debemos comprender que el sonido característico de un piano no es algo rígido sino que es algo que puede modificarse dentro de un cierto rango."
      }
    },
    repair: {
      title: "Reparación",
      p1: "Si un piano ha perdido parte de su sonoridad o si alguna de sus funciones mecánicas ya no responde adecuadamente, seguramente ese piano necesite una reparación y puesta a punto.",
      p2: <React.Fragment>En ciertas ocasiones un piano, bien sea por su uso o por el paso del tiempo, puede sufrir el
        deterioro o la rotura de alguno de sus componentes. En esos casos, la sustitución del elemento dañado por uno
        nuevo y los ajustes correspondientes devolverán al instrumento su estado natural de <strong>eficiencia y óptimo
          funcionamiento</strong>.
        Es importante que ésta intervención sea realizada por un técnico de pianos cualificado, ya que es la única
        garantía de que se realice correctamente.</React.Fragment>,
      p3: <React.Fragment>Debido a que el cambio o sustitución de estos componentes pueden diferir mucho de un piano a
        otro, <strong>se hará una valoración y presupuesto sobre estos trabajos en su domicilio sin ningún
          compromiso</strong>.</React.Fragment>
    },
    prices: {
      title: "Precios",
      p1: <React.Fragment><strong>¡Cada piano es un mundo!</strong><br/>No es de extrañar que en la mayoría de casos, ya
        sea nuevo o antiguo, las reparaciones necesarias solo se puedan determinar tras una comprobación previa del
        estado del piano.</React.Fragment>,
      p2: <React.Fragment>Por ende, <strong>todos los precios a continuación sirven únicamente como guía</strong> y se
        podrá solicitar siempre un <strong>presupuesto</strong> sin compromiso alguno. Los precios a continuación no
        incluyen gastos de desplazamiento. Para más información no dudéis en</React.Fragment>,
      p2link: "contactar conmigo",
      tuning: "Afinación",
      tuningDiscount: "de descuento para escuelas de música, conservatorios y hoteles.",
      pitchRaise: "Subida de tono",
      regulation: "Regulación",
      voicing: "Entonación",
      repair: "Reparación",
      repairText: "Precio bajo presupuesto."
    },
    contact: {
      title: "Contacto",
    }
  },
  common: {
    price: "Precio",
    visitVariation: "Varía en función de las visitas",
    pianoVariation: "Varía en función del piano",
    pianoTechnician: "Técnico de piano",
    job: "Afinación, mantenimiento y reparación de pianos en Mallorca"
  }
}

export default es;