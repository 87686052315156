import React, { useContext } from "react";
import { TranslateContext } from "./wrappers/TranslateWrapper";
import { Helmet } from "react-helmet";

const HelmetTranslate = () => {
  const { translate } = useContext(TranslateContext)
  return (
    <Helmet>
      <title>{translate("seo.title")}</title>
      <meta name="description" content={translate("seo.description")}/>
    </Helmet>
  );
}

export default HelmetTranslate;