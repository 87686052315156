import React from "react";
import Router from "./router/Router";
import TranslateWrapper, { defaultLang, languages } from "./components/wrappers/TranslateWrapper";
import ReactGA from "react-ga4";
import HelmetTranslate from "./components/HelmetTranslate";

const trackingId = "G-5538CN4NSX";

ReactGA.initialize(trackingId);

const App = () => {

  let language = defaultLang;
  const navigatorLanguage = window.navigator?.language || navigator?.browserLanguage;
  const pathnameLanguage = window.location.pathname.split("/")[1];

  if (!!pathnameLanguage && languages.includes(pathnameLanguage)) language = pathnameLanguage
  else if (!!navigatorLanguage && languages.includes(navigatorLanguage)) language = navigatorLanguage

  return (
    <TranslateWrapper lang={language}>
      <HelmetTranslate/>
      <Router basename={language}/>
    </TranslateWrapper>
  )
}

export default App;
